<template>
  <div class="icon-option is-clickable" :style="{ 'background-color': color }" :disabled="true" @click="selected">
    <template v-if="size < 769">
      <img class="img-icon-mobile" :src="src" :alt="item.label" :title="src" />
    </template>
    <template v-else>
      <a href="#" @click.prevent>
        <img class="img-icon" :src="src" :alt="item.label" />
      </a>
      <p>{{ item.label }}</p>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import { ItemNextMatchesSelect } from '@/utils/analytics';

export default {
  name: 'ItemNextMatches',
  props: {
    item: {
      type: Object,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    size: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
    ...mapGetters('general', ['getPreviewSectionAnalytics']),
    isMobile() {
      return this.$mq === 'mobile';
    },
    isSelected() {
      return this.item.id === this.id;
    },
    color() {
      return this.isSelected ? '#D6EDC1' : '';
    },
    src() {
      return this.item.src + (this.isSelected ? '-selected.svg' : '.svg');
    },
  },
  methods: {
    ...mapMutations('nextGeneral', ['updateIdAssetSelected']),
    ...mapMutations('general', ['setSectionAnalytics']),
    ...mapMutations(['SET_PREMIUM_MESSAGE']),
    ...mapActions('alertGeneral', ['setAlert']),
    ...mapGetters('nextGeneral', ['isLive', 'gameId']),
    selected() {
      ItemNextMatchesSelect(
        this.item.component.toLowerCase(),
        this.getPremiumAccount,
        this.isLive(),
        this.gameId(),
        this.getPreviewSectionAnalytics,
        this.getIsGolstats,
      );
      if (![7, 9].includes(this.item.id) || this.getPremiumAccount) {
        this.updateIdAssetSelected(this.item.id);
      } else {
        this.setSectionAnalytics(this.item.banner);
        this.SET_PREMIUM_MESSAGE(true);
      }
    },
  },
};
</script>

<style scoped>
p {
  font-size: 0.5rem;
  font-weight: bold;
}
.img-icon {
  display: block;
  margin: auto;
  width: 2.5rem;
  height: 2.6rem;
  vertical-align: baseline;
}
.icon-option {
  padding: 8px 4px 0 4px;
}
.icon-option:hover {
  background-color: #d6edc16b;
}
.img-icon-mobile {
  display: block;
  margin: auto;
}
</style>
